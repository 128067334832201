import { FunctionComponent } from "react";
import styled from "styled-components";
import {
  Column5, CustomLabel, DatePickerWrapper,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell,
  LocalPostCodeInput,
  LocalTextInputExtend, Note,
  SectionHeadingLarge, WordBreakLabel
} from "../commonStyle"
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import {ItemTextInput} from "../../EmploymentConditionsFormPage/commonStyles";
import {LocalTextInput, NoteInputWrapper} from "../../../../job/pages/JobFormPage/commonStyle";
import DatePicker from "../../../../core/components/DatePicker";
interface SupportOrganizationBaseSectionProps extends SupportPlanFormProps {
}

const LocalRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const SupportOrganizationBaseSection: FunctionComponent<SupportOrganizationBaseSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>Ⅲ. 登録支援機関（基本情報）</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 登録番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>登録番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalRow>
                <ItemTextInput
                  placeholder="23"
                  value={supportPlan?.supportOrganizationBaseRegistrationNumberPrefix ?? ""}
                  onTextChange={val => onChangeSupportPlan?.({ supportOrganizationBaseRegistrationNumberPrefix: val })}
                  error={isEmpty(supportPlan?.supportOrganizationBaseRegistrationNumberPrefix)}
                />
                登
                <ItemTextInput
                  placeholder="008552"
                  value={supportPlan?.supportOrganizationBaseRegistrationNumberSuffix ?? ""}
                  onTextChange={val => onChangeSupportPlan?.({ supportOrganizationBaseRegistrationNumberSuffix: val })}
                  error={isEmpty(supportPlan?.supportOrganizationBaseRegistrationNumberSuffix)}
                />
              </LocalRow>
              {(isEmpty(supportPlan?.supportOrganizationBaseRegistrationNumberPrefix) || isEmpty(supportPlan?.supportOrganizationBaseRegistrationNumberSuffix))
                && <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 登録年月日 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>登録年月日</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <DatePickerWrapper>
                <DatePicker
                  placeholder="2000/01/23"
                  selected={
                    supportPlan?.supportOrganizationBaseRegistrationDate
                      ? new Date(supportPlan?.supportOrganizationBaseRegistrationDate)
                      : undefined
                  }
                  onChange={(val) =>
                    onChangeSupportPlan?.({supportOrganizationBaseRegistrationDate: val.toISOString()})
                  }
                  customInput={<LocalTextInput/>}
                  error={isEmpty(supportPlan?.supportOrganizationBaseRegistrationDate)}
                />
              </DatePickerWrapper>
              {isEmpty(supportPlan?.supportOrganizationBaseRegistrationDate) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援業務を開始する予定年月日 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援業務を開始する予定年月日</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <DatePickerWrapper>
                <DatePicker
                  placeholder="2000/01/23"
                  selected={
                    supportPlan?.supportOrganizationBaseScheduledStartDate
                      ? new Date(supportPlan?.supportOrganizationBaseScheduledStartDate)
                      : undefined
                  }
                  onChange={(val) =>
                    onChangeSupportPlan?.({supportOrganizationBaseScheduledStartDate: val.toISOString()})
                  }
                  customInput={<LocalTextInput/>}
                  error={isEmpty(supportPlan?.supportOrganizationBaseScheduledStartDate)}
                />
              </DatePickerWrapper>
              {isEmpty(supportPlan?.supportOrganizationBaseScheduledStartDate) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 氏名又は名称 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>氏名又は名称</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInput
                placeholder="サンプル株式会社"
                value={supportPlan?.supportOrganizationBaseName ?? ""}
                error={isEmpty(supportPlan?.supportOrganizationBaseName)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({
                    supportOrganizationBaseName: val
                  });
                }}
              />
              {isEmpty(supportPlan?.supportOrganizationBaseName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 氏名又は名称ふりがな */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>氏名又は名称ふりがな</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="さんぷるかぶしきがいしゃ"
                value={supportPlan?.supportOrganizationBaseNameFurigana ?? ""}
                error={isEmpty(supportPlan?.supportOrganizationBaseNameFurigana)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({supportOrganizationBaseNameFurigana: val});
                }}
              />
              {isEmpty(supportPlan?.supportOrganizationBaseNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>郵便番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <Note>ハイフンを除いた半角数字で入力してください</Note>
              <LocalPostCodeInput
                value={supportPlan?.supportOrganizationBasePostalCode ?? ""}
                onTextChange={(val) => {
                  onChangeSupportPlan?.({ supportOrganizationBasePostalCode: val });
                }}
                onPostalCodeAddressIdChange={(val) =>
                  onChangeSupportPlan?.({ supportOrganizationBasePostalCodeAddressId: val })
                }
                onPrefectureChange={(val) => {
                  onChangeSupportPlan?.(
                    { supportOrganizationBasePrefecture: val.jaPrefecture }
                  );
                }}
                onCityChange={(val) => {
                  onChangeSupportPlan?.(
                    { supportOrganizationBaseCityWard: val.jaCity }
                  );
                }}
                onTownChange={(val) => {
                  onChangeSupportPlan?.(
                    { supportOrganizationBaseTown: val.jaTown }
                  );
                }}
                error={isEmpty(supportPlan?.supportOrganizationBasePostalCode)}
                suppressErrorMessage
              />
              {isEmpty(supportPlan?.supportOrganizationBasePostalCode) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>都道府県</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.supportOrganizationBasePrefecture ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>市区</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.supportOrganizationBaseCityWard ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>町村</CustomLabel>
          </LabelCell>
          <FieldCell>
            <NoteInputWrapper>
              <Note>郵便番号から自動で入力されます</Note>
              <LocalTextInput
                readOnly
                disabled
                value={
                  supportPlan?.supportOrganizationBaseTown ?? ""
                }
              />
            </NoteInputWrapper>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>番地</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="1-2-3"
                value={supportPlan?.supportOrganizationBaseAddressNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ supportOrganizationBaseAddressNumber: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseAddressNumber)}
              />
              {isEmpty(supportPlan?.supportOrganizationBaseAddressNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>建物名等</CustomLabel>
          </LabelCell>
          <FieldCell>
            <ItemTextInput
              placeholder="サンプルビル 10F"
              value={supportPlan?.supportOrganizationBaseBuilding ?? ""}
              onTextChange={val => onChangeSupportPlan?.({ supportOrganizationBaseBuilding: val })}
            />
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>電話番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="03-1234-5678"
                value={supportPlan?.supportOrganizationBasePhoneNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ supportOrganizationBasePhoneNumber: val })}
                error={isEmpty(supportPlan?.supportOrganizationBasePhoneNumber)}
              />
              {isEmpty(supportPlan?.supportOrganizationBasePhoneNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 代表者の名字 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>代表者の名字</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="山田"
                value={supportPlan?.supportOrganizationBaseRepresentativeLastName ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationBaseRepresentativeLastName: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseRepresentativeLastName)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationBaseRepresentativeLastName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 代表者の名前 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>代表者の名前</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="太郎"
                value={supportPlan?.supportOrganizationBaseRepresentativeFirstName ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationBaseRepresentativeFirstName: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseRepresentativeFirstName)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationBaseRepresentativeFirstName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 代表者の名字ふりがな */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>代表者の名字ふりがな</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="やまだ"
                value={supportPlan?.supportOrganizationBaseRepresentativeLastNameFurigana ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationBaseRepresentativeLastNameFurigana: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseRepresentativeLastNameFurigana)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationBaseRepresentativeLastNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 代表者の名前ふりがな */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>代表者の名前ふりがな</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <ItemTextInput
                placeholder="たろう"
                value={supportPlan?.supportOrganizationBaseRepresentativeFirstNameFurigana ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationBaseRepresentativeFirstNameFurigana: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseRepresentativeFirstNameFurigana)}
              >
              </ItemTextInput>
              {isEmpty(supportPlan?.supportOrganizationBaseRepresentativeFirstNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default SupportOrganizationBaseSection;