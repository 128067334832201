import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import InterviewSettings from './InterviewSettings';
import EmployerOrganizationSettings from './EmployerOrganizationSettings';
import OrganizationSettingsPanel from './OrganizationSettingsPanel';
import UserManagement from './UserManagement';
import DocumentsSettings from './DocumentsSettings/DocumentsSettings';
import EmploymentOffer from './DocumentsSettings/EmploymentOffer';
import EmploymentCondition from './DocumentsSettings/EmploymentCondition';
import { useReducerContext } from '../../contexts/ReducerContext';
import PartnerOrganizationSettings from './PartnerOrganizationSettings';

type SettingsPageProps = {
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding: 40px;
  background: #FAF6F4;
  
  > *:not(:first-child) {
    margin-left: 30px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
`;

const SettingsPage: FunctionComponent<SettingsPageProps> = () => {
    const { state } = useReducerContext();
    const { isEmployer, isPartner } = state;
    return (
        <Container>
            <OrganizationSettingsPanel />
            <Content>
                <Routes>
                    { isEmployer &&
                      <>
                      <Route path="organization" element={<EmployerOrganizationSettings />} />
                      <Route path="interview" element={<InterviewSettings />} />
                      <Route path="documents" element={<DocumentsSettings />} />
                      <Route path="documents/employment_offer" element={<EmploymentOffer />} />
                      <Route path="documents/employment_condition" element={<EmploymentCondition />} />
                      </>
                    }
                    
                    { isPartner &&
                      <Route path="organization" element={<PartnerOrganizationSettings />} />
                    }

                    { (isEmployer || isPartner) &&
                      <Route path="users" element={<UserManagement />} />
                    }
                </Routes>
            </Content>
        </Container>
    );
};

export default SettingsPage;