import { apiUtil } from '../utils';
import { Employer } from '../types/api';

export const updateEmployer = async (employerId: number | string, employer: Partial<Employer>) => {
    const response = await apiUtil.patch(
        `/employers/${employerId}`,
        {
            ...employer,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Employer;
    }
    throw new Error('API error');
};
