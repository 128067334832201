import {
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Button from "./Button";
import { ReactComponent as Arrow } from "../../assets/icon-arrow-down.svg";
import { useTranslation } from "react-i18next";
import { MOBILE_DEVICE } from "../constants/styles";

interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  headingText?: string;
  isError?: boolean;
  additionalComponentInHeading?: ReactNode;
  allowContentOverflow?: boolean;
}

interface ComponentProps {
  active?: boolean;
  allowContentOverflow?: boolean;
}


const Container = styled.div<ComponentProps>`
  width: 100%;
  border-radius: 12px;
  border: 1px solid #c2c2c2;
  background-color: white;
  overflow: ${(props) => (props.allowContentOverflow ? "visible" : "hidden")};
`;

const SectionHeadingLarge = styled.h3`
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #444;
`;

const ArrowIcon = styled(Arrow)<ComponentProps>`
  margin-left: 7px;
  transition: all 300ms ease-in-out;
  path {
    fill: #5f6368 !important;
  }
`;

const ToggleButton = styled(Button)<ComponentProps>`
  border-radius: 100px;
  padding-left: 10px;
  padding-right: 5px;
  border: none;
  align-items: center;

  ${ArrowIcon} {
    transform: ${(props) => (props.active ? "rotate(180deg)" : "none")};
  }

  @media ${MOBILE_DEVICE} {
    width: auto;
  }
`;

const Heading = styled.div<ComponentProps>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 44px;
  padding: 30px 0 30px 30px;
  border-bottom: ${(props) => (props.active ? "1px solid #C2C2C2" : "none")};
`;

const ClickableWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Content = styled.div`
  height: auto;
  transition: all 400ms ease-in-out;
  overflow: hidden;
`;

const Accordion: FunctionComponent<AccordionProps> = ({
  active,
  headingText,
  additionalComponentInHeading,
  allowContentOverflow,
  children,
  ...props
}) => {
  const { t: tCore } = useTranslation("translation", { keyPrefix: "core" });
  const contentRef = useRef(null);
  const [isActive, setIsActive] = useState(active ?? false);

  useEffect(() => {
    if (active == null) 
      return;

    setIsActive(active);
  }, [active]);

  useEffect(() => {
    if (!contentRef.current)
      return;

    const content = contentRef.current as HTMLDivElement;

    if (isActive) {
      content.style.height = `${content.scrollHeight}px`;
      setTimeout(() => {
        content.style.height = "auto";

        if (allowContentOverflow)
          content.style.overflow = "visible";
      }, 400);
    } else {
      content.style.height = "0";
      content.style.overflow = "hidden";
    }
  
  }, [isActive]);

  return (
    <Container 
      allowContentOverflow={allowContentOverflow}
      {...props}
    >
      <Heading active={isActive}>
        <ClickableWrapper
          onClick={e => {
            e.stopPropagation();
            setIsActive(!isActive);
          }}
        >
          <SectionHeadingLarge>{headingText}</SectionHeadingLarge>
          <ToggleButton variant="inline" active={isActive}>
            {isActive ? tCore('close') : tCore('open')}
            <ArrowIcon />
          </ToggleButton>
          { additionalComponentInHeading }
        </ClickableWrapper>
      </Heading>
      <Content ref={contentRef}>{children}</Content>
    </Container>
  );
};

export default Accordion;
