import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
  RadioButtonsContainer,
  ItemTextInputCompact,
} from "../../commonStyles";
import Radio from "../../../../../core/components/Radio";
import { EcContractType } from "../../../../../core/enums/employmentConditions";
import { FormBlockSectionProps } from "../../types";

interface PlaceOfEmploymentSectionProps extends FormBlockSectionProps {}

const PlacementOfEmploymentSection: FunctionComponent<PlaceOfEmploymentSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {

  return (
    <FormSection>
      <SectionHeading>就業の場所</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>雇用形態</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
                checked={
                  ec?.placeOfEmploymentContractType === EcContractType.DirectEmployment
                }
                value={EcContractType.DirectEmployment}
                onValueChange={val => onChange?.({ placeOfEmploymentContractType: val })}
            >
              直接雇用
            </Radio>
            <Radio
                checked={
                  ec?.placeOfEmploymentContractType === EcContractType.DispatchEmployment
                }
                value={EcContractType.DispatchEmployment}
                onValueChange={val => onChange?.({ placeOfEmploymentContractType: val })}
            >
              派遣雇用
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>事業所名（日本語）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="取手工場" 
            value={ec?.placeOfEmploymentJaOfficeName ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentJaOfficeName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>事業所名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Toride Koujou" 
            value={ec?.placeOfEmploymentEnOfficeName ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentEnOfficeName: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>郵便番号</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            placeholder="1080075"
            value={ec?.placeOfEmploymentPostalCode ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentPostalCode: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>都道府県</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="東京都" 
            value={ec?.placeOfEmploymentJaPrefecture ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentJaPrefecture: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>市区</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="港区" 
            value={ec?.placeOfEmploymentJaCityWard ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentJaCityWard: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>町村</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="海岸" 
            value={ec?.placeOfEmploymentJaTown ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentJaTown: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>番地</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="１丁目９−１８" 
            value={ec?.placeOfEmploymentJaAddressNumber ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentJaAddressNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>建物名等</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="國際浜松町ビル 2階" 
            value={ec?.placeOfEmploymentJaBuilding ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentJaBuilding: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>都道府県（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Tokyo" 
            value={ec?.placeOfEmploymentEnPrefecture ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentEnPrefecture: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>市区（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Minato-ku" 
            value={ec?.placeOfEmploymentEnCityWard ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentEnCityWard: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>町村（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="kaigan" 
            value={ec?.placeOfEmploymentEnTown ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentEnTown: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>番地（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="1-9-18" 
            value={ec?.placeOfEmploymentEnAddressNumber ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentEnAddressNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>建物名等（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Kokusai Hamamatsucho Building 2F" 
            value={ec?.placeOfEmploymentEnBuilding ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentEnBuilding: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>連絡先</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            placeholder="03-1234-5678" 
            value={ec?.placeOfEmploymentPhoneNumber ?? ""}
            onTextChange={val => onChange?.({ placeOfEmploymentPhoneNumber: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>雇入れ後の変更の可能性</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <RadioButtonsContainer>
            <Radio
                checked={ec?.placeOfEmploymentChangesAfterEmployment === true}
                onValueChange={() => onChange?.({ placeOfEmploymentChangesAfterEmployment: true })}
            >
              あり
            </Radio>
            <Radio
                checked={ec?.placeOfEmploymentChangesAfterEmployment === false}
                onValueChange={() => onChange?.({ placeOfEmploymentChangesAfterEmployment: false })}
            >
              なし
            </Radio>
          </RadioButtonsContainer>
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>事業所名（日本語）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="取手工場" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentJaOfficeNameAfterChange ?? ""
                : ""
            }
            onTextChange={val => onChange?.({ placeOfEmploymentJaOfficeNameAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>事業所名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="Toride Koujou" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentEnOfficeNameAfterChange ?? ""
                : ""
            }
            onTextChange={val => onChange?.({ placeOfEmploymentEnOfficeNameAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>郵便番号</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            placeholder="1080075"
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentPostalCodeAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentPostalCodeAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>都道府県</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="東京都" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
              ? ec?.placeOfEmploymentJaPrefectureAfterChange ?? ""
              : ""
            }
            onTextChange={val => onChange?.({ placeOfEmploymentJaPrefectureAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>市区</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="港区" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentJaCityWardAfterChange ?? ""
                : ""
            }
            onTextChange={val => onChange?.({ placeOfEmploymentJaCityWardAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>町村</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="海岸" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentJaTownAfterChange ?? "" 
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentJaTownAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>番地</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="１丁目９−１８" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentJaAddressNumberAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentJaAddressNumberAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>建物名等</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="國際浜松町ビル 2階" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentJaBuildingAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentJaBuildingAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>都道府県（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="Tokyo" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentEnPrefectureAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentEnPrefectureAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>市区（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="Minato-ku" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentEnCityWardAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentEnCityWardAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>町村（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="kaigan" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentEnTownAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentEnTownAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>番地（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="1-9-18" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentEnAddressNumberAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentEnAddressNumberAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>建物名等（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="Kokusai Hamamatsucho Building 2F" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentEnBuildingAfterChange ?? ""
                : ""
              }
            onTextChange={val => onChange?.({ placeOfEmploymentEnBuildingAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
      <FormRow>
        <LabelContainer>
          <ItemLabel>連絡先</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInputCompact
            disabled={ec?.placeOfEmploymentChangesAfterEmployment !== true}
            placeholder="03-1234-5678" 
            value={
              ec?.placeOfEmploymentChangesAfterEmployment === true
                ? ec?.placeOfEmploymentPhoneNumberAfterChange ?? ""
                : ""  
              }
            onTextChange={val => onChange?.({ placeOfEmploymentPhoneNumberAfterChange: val })}
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default PlacementOfEmploymentSection;
