import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { getProposal, updateProposalState } from '../apis/proposals';
import { INTERVIEW_DECLINE_REASON_OPTIONS } from '../constants';
import { getAdditionalInformationTypes, getCandidate } from '../../candidate/apis/candidate';
import CandidateOverview from '../../candidate/components/CandidateOverview';
import { AdditionalInformationType, Candidate } from '../../candidate/types/api';
import ActionBar from '../../core/components/ActionBar';
import Button from '../../core/components/Button';
import DeclineConfirmationModal from '../../core/components/DeclineConfirmationModal';
import Page from '../../core/components/Page';
import Row from '../../core/components/Row';
import Text from '../../core/components/Text';
import Section from '../../core/components/Section';
import SectionHeading from '../../core/components/SectionHeading';
import ACTIONS from '../../core/constants/actions';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { getJob } from '../../job/apis/job';
import JobOverview from '../../job/components/JobOverview';
import { Job } from '../../job/types/api';
import { decodeHTMLEntities } from '../../core/utils';
import { getSalary } from '../../core/utils';
import ChatSection from '../components/ChatSection';
import { ChatMessageType } from '../types';
import styled from 'styled-components';
import { getChatMessages, sendChatMessage } from '../apis/chat';
import { useJobLocation, useJobPostCategory, useNationalities } from '../../core/hooks';

interface ProposalReviewPageProps {
};

const LocalRow = styled(Row)`
    width: 100%;
    justify-content: space-between;
`;

const ScoutIndicator = styled(Text)`
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #FFDFD1;
`;

const ProposalReviewPage: FunctionComponent<ProposalReviewPageProps> = () => {
    const { state, dispatch } = useReducerContext();
    const jobPostCategory = useJobPostCategory();
    const { getJobLocation } = useJobLocation();
    const { getNationalityName } = useNationalities();
    const [job, setJob] = useState<Job|null>(null);
    const [candidate, setCandidate] = useState<Candidate|null>(null);
    const [messages, setMessages] = useState<ChatMessageType[]>([]);
    const [fromScoutedCandidate, setFromScoutedCandidate] = useState<boolean>(false);
    const [additionalInformationTypes, setAdditionalInformationTypes] = useState<Record<number, AdditionalInformationType>>({});
    const [reason, setReason] = useState('');
    const [comment, setComment] = useState('');
    const [modalOpened, setModalOpened] = useState(false);
    const { proposalId } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleMessageSent = async (message: string) => {
        dispatch({
            type: ACTIONS.START_LOADING,
        });
        try {
            const response = await sendChatMessage(proposalId!, message);
            setMessages([
                ...messages,
                response,
            ]);
        } catch (e) {
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleAccept = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.accepting_proposal'),
            },
        })
        try {
            await updateProposalState(proposalId!, 'first_interview');
            navigate(`/applications/${proposalId}`);
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        })
    }

    const handleDecline = async () => {
        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.declining_proposal'),
            },
        })
        try {
            await updateProposalState(proposalId!, 'declined', reason, comment);
            navigate('/applications');
        } catch (e) {
            // TODO: error handling
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        })
    }

    useEffect(() => {
        (async () => {
            dispatch({
                type: ACTIONS.START_LOADING,
                payload: {
                    message: t('proposal.fetching_proposal_details'),
                },
            });
            try {
                const proposal = await getProposal(proposalId!);
                if (state.isEmployer && proposal.state !== 'screen') {
                    navigate(`/applications/${proposalId}`);
                }
                setFromScoutedCandidate(!!proposal.scoutId);
                const job = await getJob(proposal.jobPostId);
                setJob(job);
                const candidate = await getCandidate(proposal.candidateId);
                setCandidate(candidate);
                const messages = await getChatMessages(proposalId!)
                setMessages(messages);
                const additionalInformationTypes: Record<number, AdditionalInformationType> = {};
                for (const additionalInformationType of await getAdditionalInformationTypes()) {
                    additionalInformationTypes[additionalInformationType.id] = additionalInformationType;
                }
                setAdditionalInformationTypes(additionalInformationTypes);
            } catch (e) {
                // TODO: error handling
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            })
        })()
    }, []);

    return (
        <Page>
            <DeclineConfirmationModal
                open={modalOpened}
                title={t('proposal.decline_proposal_title')}
                message={t('proposal.decline_proposal_message')}
                options={INTERVIEW_DECLINE_REASON_OPTIONS}
                reason={reason}
                placeholder={t('proposal.decline_proposal_example')}
                comment={comment}
                onReasonChange={setReason}
                onCommentChange={setComment}
                onConfirm={handleDecline}
                onCancel={() => setModalOpened(false)}
            />
            <LocalRow>
                <SectionHeading>{t('proposal.job')}</SectionHeading>
                {
                    /* Tentatively put here because the current design is different from the latest.
                       When the layout of this page is updated, this indicator should move to another place */
                    fromScoutedCandidate &&
                    <ScoutIndicator>{t('scout.applied_via_scout')}</ScoutIndicator>
                }
            </LocalRow>
            <Section>
                {job && (
                    <JobOverview
                        thumbnail={job.images.length
                            ? job.images[0].url
                            : `/images/job-type-thumbnails/${job.jobTypeId}.webp`
                        }
                        title={
                            decodeHTMLEntities(job.translations.find(t => t.language.code === i18n.language)?.name)
                            ?? job.name
                        }
                        location={getJobLocation(job)}
                        industry={jobPostCategory.getIndustryNameByJobTypeId(job.jobTypeId)}
                        job={jobPostCategory.getJobTypeName(job.jobTypeId)}
                        salaryType={job.salaryType}
                        salary={getSalary(job)}
                    />
                )}
            </Section>

            <Row>
                <SectionHeading>{t('proposal.candidate')}</SectionHeading>
            </Row>
            <Section>
                {candidate && (
                    <CandidateOverview
                        additionalInformationTypes={additionalInformationTypes}
                        avatar={candidate.image?.url}
                        name={`${candidate.enFirstName} ${candidate.enLastName}`}
                        age={candidate.age}
                        gender={candidate.gender}
                        nationality={getNationalityName(candidate.nationalityId)}
                        japaneseLevel={candidate.jlptLevel}
                        visa={candidate.visaStatus}
                        address={candidate.currentAddress}
                        workHours={candidate.currentWorkHours}
                        nearestPossibleDate={candidate.nearestPossibleDate}
                        additionalInformation={candidate.additionalInformations}
                    />
                )}
            </Section>

            <ChatSection
                style={{ marginTop: 20 }}
                messages={messages}
                onMessageSent={handleMessageSent}
            />

            <ActionBar>
                <Button onClick={handleAccept}>{t('proposal.accept')}</Button>
                <Button
                    variant="secondary"
                    onClick={() => setModalOpened(true)}
                >
                    {t('proposal.decline')}
                </Button>
            </ActionBar>
        </Page>
    );
};

export default ProposalReviewPage;