import { FunctionComponent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Job } from '../../types/api';
import { useReducerContext } from '../../../core/contexts/ReducerContext';
import JobStatus from '../../components/JobStatus'; 
import { decodeHTMLEntities } from '../../../core/utils';
import { JobState } from '../../enums/job';
type JobListTableProps = {
    jobs: Job[];
    onItemClick?: (index: number) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #E5E5E5;
  border: 1px solid #D5D5D5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-top: none;
  cursor: pointer;
  
  &:hover {
    background: #FFDFD1;
    border-color: #FF730D;
    border-top: 1px solid #FF730D;
  }
`;

const Item = styled.div`
  flex: 1;
  padding-left: 20px;
  font-size: 14px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Status = styled(Item)`
    flex: 1
`;

const Name = styled(Item)`
    flex: 3;
`;

const Employer = styled(Item)`
    flex: 5;
`;

const LastUpdated = styled(Item)`
  flex: 1;
`;

const JobListTable: FunctionComponent<JobListTableProps> = ({
    jobs,
    onItemClick
}) => {
    const { state } = useReducerContext();
    const { t, i18n } = useTranslation();

    return (
        <Container>
            <Header>
                <Status>{t('core.status')}</Status>
                <Name>{t('core.name')}</Name>
                <Employer>{t('core.employer')}</Employer>
                <LastUpdated>{t('core.last_updated')}</LastUpdated>
            </Header>
            {jobs.map((job, index) => (
                <Row key={job.id} onClick={() => onItemClick && onItemClick(index)}>
                    <Status>
                        <JobStatus variant={job.state ?? JobState.Draft} />
                    </Status>
                    <Name>
                        {
                            job.state === JobState.Published
                            ? (decodeHTMLEntities(job.translations?.find(trans => trans.language.code === i18n.language)?.name) ?? job.name)
                            : job.name
                        }
                    </Name>
                    <Employer>{state.company?.jaName}</Employer>
                    <LastUpdated>
                        {moment(job.updatedAt).format('YYYY/MM/DD')}
                    </LastUpdated>
                </Row>
            ))}
        </Container>
    );
};

export default JobListTable;