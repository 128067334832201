import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../components/Button';
import Column from '../components/Column';
import Label from '../components/Label';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Text from '../components/Text';
import TextInput from '../components/TextInput';
import { useReducerContext } from '../contexts/ReducerContext';
import ACTIONS from '../constants/actions';
import { 
    confirmInvitation,
    getUserByConfirmationToken,
    initializePassword, 
    updateUserSettings, 
    verifyEmail 
} from '../apis/user';
import { MOBILE_DEVICE } from '../constants/styles';
import Section from '../components/Section';
import { signIn } from '../apis/auth';
import { User, UserSetting } from '../types/api';
import { getLanguageIdFromCode } from '../utils';

interface EmailVerificationPageProps {
}

const LocalPage = styled(Page)`
  > * {
    width: 500px;
  }

  @media ${MOBILE_DEVICE} {
    > * {
      width: 100%;
    }
  }
`;

const LocalPageHeading = styled(PageHeading)`
  font-size: 20px;
  line-height: 29px;
  font-weight: 500;
`;

const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
    background: transparent;
    border: none;
  }
`;

const LocalTextInput = styled(TextInput)`
  width: 100%;
`;

const LocalButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  
  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`;

const InfoContainer = styled(Column)`
  gap: 10px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`

const FormContainer = styled(Column)`
  gap: 20px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 15px;
  }
`

const InputContainer = styled(Column)`
  gap: 3px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 5px;
  }
`

const EmailVerificationPage: FunctionComponent<EmailVerificationPageProps> = () => {
    const { dispatch } = useReducerContext();
    const [searchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [user, setUser] = useState<User>();
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordConfirmationError, setPasswordConfirmationError] = useState(false);
    const confirmationToken = (searchParams.get('confirmation_token') ?? '') as string;
    const companyId = user?.company?.id;

    const handleFirstNameChange = (value: string) => {
        setFirstName(value);
        setFirstNameError(false);
    }

    const handleFirstNameBlur = () => {
        setFirstNameError(firstName.length < 1);
    }

    const handleLastNameChange = (value: string) => {
        setLastName(value);
        setLastNameError(false);
    }

    const handleLastNameBlur = () => {
        setLastNameError(lastName.length < 1);
    }

    const handlePasswordChange = (value: string) => {
        setPassword(value);
        setPasswordError(false);
    };

    const handlePasswordBlur = () => {
        setPasswordError(password.length < 8);
    };

    const handlePasswordConfirmationChange = (value: string) => {
        setPasswordConfirmation(value);
        setPasswordConfirmationError(false);
    };

    const handlePasswordConfirmationBlur = () => {
        setPasswordConfirmationError(password !== passwordConfirmation);
    };

    const handleSubmit = async () => {
        if (searchParams.has('confirmation_token')) {
            dispatch({
                type: ACTIONS.START_LOADING,
            });
            try {
                const isInvited = !!companyId;

                if (isInvited) {
                    await confirmInvitation(
                        searchParams.get('confirmation_token') as string,
                        firstName,
                        lastName,
                        password
                    );
                } else {
                    await initializePassword(
                        searchParams.get('confirmation_token') as string,
                        password
                    );
                }
                
                if (!user?.email) {
                    navigate('/login');
                    return;
                }

                await signIn(user.email, password);
                dispatch({
                    type: ACTIONS.LOGIN,
                    payload: {
                        company: user.company,
                        candidate: user.candidate,
                        isEmployer: !!user.company?.employer?.id,
                        isPartner: !!user.company?.partner?.id,
                        isCandidate: !!user.candidate?.id,
                        userSetting: user.userSetting
                    },
                });

                if (isInvited) {
                    const updatedSetting: UserSetting = await updateUserSettings(
                        user.userSetting.id, 
                        { 
                            languageId: getLanguageIdFromCode(i18n.language)
                        }
                    );
                
                    dispatch({
                        type: ACTIONS.UPDATE_USER_SETTING,
                        payload: updatedSetting
                    });
            
                    i18n.changeLanguage(i18n.language);
                    localStorage.setItem('lng', i18n.language);
                    navigate('/');
                } else {
                    navigate('/account-created');
                }
            } catch (e) {
                //TODO: handle a failed case
            } finally {
                dispatch({
                    type: ACTIONS.STOP_LOADING,
                });
            }
        }
    };


    useEffect(() => {
        (async () => {
            if (confirmationToken) {
                dispatch({
                    type: ACTIONS.START_LOADING,
                });
                try {
                    const user = await getUserByConfirmationToken(confirmationToken);
                    setUser(user);

                    if (!user.company) {
                        await verifyEmail(confirmationToken);
                    }
                } catch (e) {
                    navigate('/login');
                } finally {}
                dispatch({
                    type: ACTIONS.STOP_LOADING,
                });
            }
        })();
    }, []);

    return (
        <LocalPage>
            <LocalSection>
                <Container>
                    <InfoContainer>
                        <LocalPageHeading>
                            {companyId
                                ? t('core.welcome_to_organization', {
                                    organization: user?.company?.jaName ?? '-',
                                })
                                : t('core.email_verified')
                            }
                        </LocalPageHeading>
                        {!companyId && (
                            <Text>
                                {t('core.email_verified_description')}
                            </Text>
                        )}
                    </InfoContainer>

                    <FormContainer>
                        {!!companyId && (
                            <>
                                <InputContainer>
                                    <Label required>{t('core.first_name')}</Label>
                                    <Column style={{ width: '100%' }}>
                                        <LocalTextInput
                                            error={firstNameError}
                                            value={firstName}
                                            onTextChange={handleFirstNameChange}
                                            onBlur={handleFirstNameBlur}
                                        />
                                    </Column>
                                </InputContainer>
                                <InputContainer>
                                    <Label required>{t('core.last_name')}</Label>
                                    <Column style={{ width: '100%' }}>
                                        <LocalTextInput
                                            error={lastNameError}
                                            value={lastName}
                                            onTextChange={handleLastNameChange}
                                            onBlur={handleLastNameBlur}
                                        />
                                    </Column>
                                </InputContainer>
                            </>
                        )}

                        <InputContainer>
                            <Label required>{t('core.set_password')}</Label>
                            <Column style={{ width: '100%' }}>
                                <LocalTextInput
                                    type="password"
                                    error={passwordError}
                                    value={password}
                                    onTextChange={handlePasswordChange}
                                    onBlur={handlePasswordBlur}
                                />
                                <Text
                                    style={{ marginTop: 4 }}
                                    variant="tiny"
                                    error={passwordError}
                                >
                                    {t('core.password_restriction')}
                                </Text>
                            </Column>
                        </InputContainer>

                        <InputContainer>
                            <Label required>{t('core.confirm_password')}</Label>
                            <Column style={{ width: '100%' }}>
                                <LocalTextInput
                                    type="password"
                                    error={passwordConfirmationError}
                                    value={passwordConfirmation}
                                    onTextChange={handlePasswordConfirmationChange}
                                    onBlur={handlePasswordConfirmationBlur}
                                />
                                {passwordConfirmationError && (
                                    <Text
                                        style={{ marginTop: 4 }}
                                        error
                                        variant="tiny"
                                    >
                                        {t('core.password_mismatch')}
                                    </Text>
                                )}
                            </Column>
                        </InputContainer>
                    </FormContainer>

                    <LocalButton
                        disabled={!password
                            || password !== passwordConfirmation
                            || (!!companyId && (!firstName || !lastName))
                        }
                        onClick={handleSubmit}
                    >
                        {t('core.create_account')}
                    </LocalButton>
                </Container>
            </LocalSection>
        </LocalPage>
    );
};

export default EmailVerificationPage;