import { getAddressInformation } from "../../../core/apis/address";
import { Company } from "../../../core/types/api";
import { isValidPostalCode, sanitizePostalCode } from "../../../core/utils";
import { Job } from "../../types/api";

type Data = {
  company?: Company | null;
}

export const prepareInitializedJobFormData = async (data: Data): Promise<Partial<Job>> => {
  const initVals: Partial<Job> = {};
  const { company } = data;
  const employer = company?.employer!;

  if (!company)
    return {};

  /* For 企業情報 / Company information section */
  
  /* 会社/企業名 / Company name */
  initVals.ecEmployerJaOrganizationName = company.jaName ?? '';
  initVals.ecEmployerEnOrganizationName = company.enName ?? '';

  /* 郵便番号 / Postal code */
  /* the company's postal code might include a hyphen 
     while other places don't accept it, so we need to sanitize it */
  const companyPostalCode = sanitizePostalCode(company.postalCode);
  initVals.ecEmployerPostalCode = companyPostalCode ?? '';

  let companyAddrInfo = null;
  if (isValidPostalCode(companyPostalCode)) {
    try {
      companyAddrInfo = await getAddressInformation(companyPostalCode!);
    } catch {
      //Probably, no need to handle error
    }
  }
  
  /* 都道府県 / Prefecture */
  initVals.ecEmployerJaPrefecture = companyAddrInfo?.jaPrefecture ?? '';
  initVals.ecEmployerEnPrefecture = companyAddrInfo?.enPrefecture ?? '';

  /* 市区 / City */
  initVals.ecEmployerJaCityWard = company.jaCityWard ?? '';
  initVals.ecEmployerEnCityWard = company.enCityWard ?? '';

  /* 町村 / Town */
  initVals.ecEmployerJaTown = company.jaTown ?? '';
  initVals.ecEmployerEnTown = company.enTown ?? '';

  /* 番地 / Street address */
  initVals.ecEmployerJaAddressNumber = company.jaAddressNumber ?? '';
  initVals.ecEmployerEnAddressNumber = company.enAddressNumber ?? '';

  /* 建物名等 / Building name */
  initVals.ecEmployerJaBuilding = company.jaBuildingName ?? '';
  initVals.ecEmployerEnBuilding = company.enBuildingName ?? '';

  /* 電話番号 / Phone number */ 
  initVals.ecEmployerPhoneNumber = company.phoneNumber ?? '';

  /* 代表者名 / Representative name */
  initVals.ecEmployerJaRepresentativeName = company.jaRepresentativeName ?? '';
  initVals.ecEmployerEnRepresentativeName = company.enRepresentativeName ?? '';

  /* 代表者役職 / Representative position */
  initVals.ecEmployerJaRepresentativeTitle = company.jaRepresentativeTitle ?? '';
  initVals.ecEmployerEnRepresentativeTitle = company.enRepresentativeTitle ?? '';


  /* For 就業に関する情報 / Employment information section */
  /* 事務所名 */
  initVals.ecPlaceOfEmploymentJaOfficeName = company.jaName ?? '';
  initVals.ecPlaceOfEmploymentEnOfficeName = company.enName ?? '';

  /* 郵便番号 / Postal code */
  initVals.postalCode = sanitizePostalCode(company.postalCode) ?? '';
  initVals.ecPlaceOfEmploymentPostalCode = sanitizePostalCode(company.postalCode) ?? '';

  /* 都道府県 / Prefecture */
  initVals.ecPlaceOfEmploymentJaPrefecture = companyAddrInfo?.jaPrefecture ?? '';
  initVals.ecPlaceOfEmploymentEnPrefecture = companyAddrInfo?.enPrefecture ?? '';
  

  /* 市区 / City */
  initVals.ecPlaceOfEmploymentJaCityWard = company.jaCityWard ?? '';
  initVals.ecPlaceOfEmploymentEnCityWard = company.enCityWard ?? '';

  /* 町村 / Town */
  initVals.ecPlaceOfEmploymentJaTown = company.jaTown ?? '';
  initVals.ecPlaceOfEmploymentEnTown = company.enTown ?? '';

  /* 番地 / Street address */
  initVals.jaAddressNumber = company.jaAddressNumber ?? '';
  initVals.enAddressNumber = company.enAddressNumber ?? '';
  initVals.ecPlaceOfEmploymentJaAddressNumber = company.jaAddressNumber ?? '';
  initVals.ecPlaceOfEmploymentEnAddressNumber = company.enAddressNumber ?? '';

  /* 建物名等 / Building name */
  initVals.jaBuildingName = company.jaBuildingName ?? '';
  initVals.enBuildingName = company.enBuildingName ?? '';
  initVals.ecPlaceOfEmploymentJaBuilding = company.jaBuildingName ?? '';
  initVals.ecPlaceOfEmploymentEnBuilding = company.enBuildingName ?? '';

  /* 電話番号 / Phone number */
  initVals.ecPlaceOfEmploymentPhoneNumber = company.phoneNumber ?? '';

  /* 賞与 / Bonus */
  initVals.bonus = employer.bonus;

  
  /* For 給与 / Salary section */
  /* 締切日/支払日1 */
  initVals.ecCompensationPayrollCutOffDate_1 = employer.compensationPayrollCutOffDate_1;
  initVals.ecCompensationPayrollDate_1 = employer.compensationPayrollDate_1;

  /* 締切日/支払日2 */
  initVals.ecCompensationPayrollCutOffDate_2 = employer.compensationPayrollCutOffDate_2;
  initVals.ecCompensationPayrollDate_2 = employer.compensationPayrollDate_2;


  return initVals;
}