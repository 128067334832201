import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { createProposal } from '../apis/proposals';
import InterviewCalendar from '../components/InterviewCalendar';
import { getAdditionalInformationTypes, getCandidate } from '../../candidate/apis/candidate';
import { AdditionalInformationType, Candidate } from '../../candidate/types/api';
import ActionBar from '../../core/components/ActionBar';
import Button from '../../core/components/Button';
import CandidateOverview from '../../candidate/components/CandidateOverview';
import JobOverview from '../../job/components/JobOverview';
import Page from '../../core/components/Page';
import Row from '../../core/components/Row';
import Section from '../../core/components/Section';
import SectionHeading from '../../core/components/SectionHeading';
import Text from '../../core/components/Text';
import ACTIONS from '../../core/constants/actions';
import { useReducerContext } from '../../core/contexts/ReducerContext';
import { Job } from '../../job/types/api';
import { getJob } from '../../job/apis/job';
import { useMediaQuery } from 'react-responsive';
import { DESKTOP_DEVICE, MOBILE_DEVICE } from '../../core/constants/styles';
import styled from 'styled-components';
import Column from '../../core/components/Column';
import Message from '../../core/components/Message';
import InterviewCalendarModal from '../components/InterviewCalendarModal';
import moment from 'moment';
import { decodeHTMLEntities } from '../../core/utils';
import { getSalary } from '../../core/utils';
import { isCandidateProfileComplete } from '../../job/utils';
import ProfileMissingModal from '../../job/components/ProfileMissingModal';
import { getScouts } from '../../scout/apis';
import { Scout } from '../../scout/types';
import { JobState } from '../../job/enums/job';
import { useJobLocation, useJobPostCategory, useNationalities } from '../../core/hooks';

interface ProposalCreationPageProps {
}

const LocalColumn = styled(Column)`
  width: 100%;
  
  > *:not(:first-child) {
    margin-top: 10px;
  }
  
  @media ${MOBILE_DEVICE} {
    > *:not(:first-child) {
      margin-top: 5px;
    }
  }
`;

const LocalSectionHeading = styled(SectionHeading)`
  margin: 0;
  
  @media ${MOBILE_DEVICE} {
    width: 100%;
    padding: 0 20px;
    margin-top: 20px;
  }
`;

const ProposalCreationPage: FunctionComponent<ProposalCreationPageProps> = () => {
    const { state, dispatch } = useReducerContext()
    const jobPostCategory = useJobPostCategory();
    const { getJobLocation } = useJobLocation();
    const { getNationalityName } = useNationalities();
    const [additionalInformationTypes, setAdditionalInformationTypes] = useState<{ [key: string]: AdditionalInformationType }>({});
    const [job, setJob] = useState<Job|null>(null);
    const [candidate, setCandidate] = useState<Candidate|null>(null);
    const [interviewTimes, setInterviewTimes] = useState<Date[]>([])
    const [interviewCalendarModalOpen, setInterviewCalendarModalOpen] = useState(false);
    const [profileMissingModalOpen, setProfileMissingModalOpen] = useState(false);
    const { jobId, candidateId } = useParams();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const isMobileDevice = useMediaQuery({
        query: MOBILE_DEVICE,
    });
    const isDesktopDevice = useMediaQuery({
        query: DESKTOP_DEVICE,
    });

    const handleProposeClick = async () => {

        if (state.isCandidate && !isCandidateProfileComplete(state.candidate!)) {
            setProfileMissingModalOpen(true);
            return;
        } 

        dispatch({
            type: ACTIONS.START_LOADING,
            payload: {
                message: t('proposal.proposing_candidate'),
            },
        });
        try {
            const proposingCandidateId = state.isCandidate? state.candidate!.id : candidateId!;
            const scouts: Scout[] = await getScouts({ 
                candidateIds: [proposingCandidateId], 
                jobPostId: jobId! 
            });
            let scoutId: number | null = null;

            // Normally, the number of scouts to a candidate for a job is up to 1.
            if (scouts.length > 0) {
                scoutId = scouts[0].id;
            }

            const proposal = await createProposal(
                jobId!,
                proposingCandidateId,
                interviewTimes,
                scoutId,
                i18n.language
            );
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'success',
                    message: t('proposal.proposal_submission_success'),
                },
            });
            navigate(`/proposals/${proposal.id}`);
        } catch (e) {
            dispatch({
                type: ACTIONS.SET_PROMPT,
                payload: {
                    type: 'warning',
                    message: t('proposal.proposal_submission_failed'),
                },
            });
        }
        dispatch({
            type: ACTIONS.STOP_LOADING,
        });
    };

    const handleCancelClick = () => {
        navigate(state.isCandidate? '/jobs' : `/jobs/${jobId}/propose`);
    };

    useEffect(() => {
        (async () => {
            try {
                const additionalInformationTypes: { [key: number]: AdditionalInformationType } = {};
                for (const type of await getAdditionalInformationTypes()) {
                    additionalInformationTypes[type.id] = type;
                }
                setAdditionalInformationTypes(additionalInformationTypes);
            } catch (e) {
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                dispatch({
                    type: ACTIONS.START_LOADING,
                    payload: {
                        message: t('job.fetching_job_details'),
                    },
                });
                const job = await getJob(jobId!);

                if (job.state === JobState.Archived) {
                    navigate('/jobs');
                    return;
                }

                setJob(job);

                dispatch({
                    type: ACTIONS.START_LOADING,
                    payload: {
                        message: t('candidate.fetching_candidate_details'),
                    },
                })
                if (state.isCandidate) {
                    setCandidate(state.candidate!);
                } else {
                    const candidate = await getCandidate(candidateId!);
                    setCandidate(candidate);
                }
            } catch (e) {
                // TODO: error handling
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            })
        })()
    }, [jobId, candidateId]);

    return (
        <Page>
            {isDesktopDevice && (
                <>
                    <Row>
                        <SectionHeading>{t('proposal.job')}</SectionHeading>
                    </Row>
                    <Section>
                        {job && (
                            <JobOverview
                                thumbnail={job.images.length
                                    ? job.images[0].url
                                    : `/images/job-type-thumbnails/${job.jobTypeId}.webp`
                                }
                                title={
                                    decodeHTMLEntities(job.translations.find(t => t.language.code === i18n.language)?.name)
                                    ?? job.name
                                }
                                location={getJobLocation(job)}
                                industry={jobPostCategory.getIndustryNameByJobTypeId(job.jobTypeId) || '-'}
                                job={jobPostCategory.getJobTypeName(job.jobTypeId) || '-'}
                                salaryType={job.salaryType}
                                salary={getSalary(job)}
                            />
                        )}
                    </Section>
                </>
            )}

            <LocalColumn>
                <Row>
                    <LocalSectionHeading>{t('proposal.candidate')}</LocalSectionHeading>
                </Row>
                <Section>
                    {candidate && (
                        <CandidateOverview
                            additionalInformationTypes={additionalInformationTypes}
                            avatar={candidate.image?.url}
                            name={`${candidate.enFirstName} ${candidate.enLastName}`}
                            age={candidate.age}
                            gender={candidate.gender}
                            nationality={getNationalityName(candidate.nationalityId)}
                            japaneseLevel={candidate.jlptLevel}
                            visa={candidate.visaStatus}
                            address={candidate.currentAddress}
                            workHours={candidate.currentWorkHours}
                            nearestPossibleDate={candidate.nearestPossibleDate}
                            additionalInformation={candidate.additionalInformations}
                        />
                    )}
                </Section>
            </LocalColumn>

            <LocalColumn>
                <Row>
                    <LocalSectionHeading>{t('proposal.suggested_interview_dates')}</LocalSectionHeading>
                </Row>
                {isMobileDevice
                    ? (
                        <Section>
                            <Button
                                style={{ width: 'unset' }}
                                variant="tertiary"
                                onClick={() => setInterviewCalendarModalOpen(true)}
                            >
                                {t('proposal.select_dates')}
                            </Button>
                            <Text style={{ marginTop: 20 }}>
                                {t('proposal.selected_dates')}
                            </Text>
                            <Message style={{ marginTop: 10 }}>
                                {interviewTimes.map((date) => (
                                    <div key={date.toISOString()}>
                                        {moment(date)
                                            .utcOffset('+09:00')
                                            .format('M/D/YYYY (ddd) H:mm')
                                        }
                                        {moment(date)
                                            .add(1, 'hour')
                                            .utcOffset('+09:00')
                                            .format(' - H:mm')
                                        }
                                        {' JST'}
                                    </div>
                                ))}
                            </Message>
                        </Section>
                    )
                    : (
                        <Section>
                            <Text>{t('proposal.suggested_interview_dates_description')}</Text>
                            <InterviewCalendar
                                style={{ marginTop: '14px' }}
                                value={interviewTimes}
                                onToggle={date => {
                                    const index = interviewTimes.findIndex(time => time.getTime() === date.getTime())
                                    if (index !== -1) {
                                        const times = [...interviewTimes]
                                        times.splice(index, 1)
                                        setInterviewTimes(times)
                                    } else if (interviewTimes.length < 20) {
                                        setInterviewTimes([...interviewTimes, date])
                                    }
                                }}
                            />
                        </Section>
                    )
                }
            </LocalColumn>

            <ActionBar>
                {isMobileDevice
                    ? (
                        <Button
                            disabled={interviewTimes.length < 3}
                            onClick={handleProposeClick}
                        >
                            { state.isCandidate? t('proposal.apply') : t('proposal.propose')}
                        </Button>
                    )
                    : (
                        <>
                            <Button
                                disabled={interviewTimes.length < 3}
                                onClick={handleProposeClick}
                            >
                                { state.isCandidate? t('proposal.apply') : t('proposal.propose')}
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={handleCancelClick}
                            >
                                {t('proposal.cancel')}
                            </Button>
                        </>
                    )
                }
            </ActionBar>

            <InterviewCalendarModal
                creationMode
                open={interviewCalendarModalOpen}
                onClose={() => setInterviewCalendarModalOpen(false)}
                onConfirm={(dates) => {
                    setInterviewTimes(dates);
                    setInterviewCalendarModalOpen(false);
                }}
            />

            <ProfileMissingModal 
                open={profileMissingModalOpen}
                onClick={() => navigate('/profile/edit')}
            />
        </Page>
    );
};

export default ProposalCreationPage;